



















































































































import Component from 'vue-class-component'
import SygniToggleSwitch from "@/components/inputs/SygniToggleSwitch.vue";
import SygniSelect from "@/components/inputs/SygniSelect.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniRadio from "@/components/inputs/SygniRadio.vue";
import SygniTextArea from "@/components/inputs/SygniTextArea.vue";
import SygniCheckbox from "@/components/inputs/SygniCheckbox.vue";
import SygniInput from "@/components/inputs/SygniInput.vue";
import ExpandCollapse from "@/components/animations/ExpandCollapse.vue";
import SygniContainerTitle from "@/components/layout/SygniContainerTitle.vue";
import {
  // EditInvestmentClientPayload,
  emptyInvestmentClient,
  InvestmentClient,
} from "@/modules/genprox/modules/fund/modules/fundraising/store/types";
import {email, required} from "vuelidate/lib/validators";
import SygniInputError from "@/components/inputs/SygniInputError.vue";
import {InvestorSelects} from "@/modules/shared/dictionary-store/types";
import {mapGetters, mapState} from "vuex";
import {Prop, Watch} from "vue-property-decorator";
import {InvestorFormViewType} from "@/modules/genprox/modules/fund/modules/fundraising/models/InvestorFormViewType.enum";
import AddNewInvestorBoilerplate from './AddNewInvestorBoilerplate.vue';
import { SelectOption } from '@/store/types';
import _ from 'lodash';
import GenproxButton from '@/components/buttons/GenproxButton.vue';
import PencilSquareIcon from '@/assets/icons/heroicons/PencilSquareIcon.vue';
// import _ from 'lodash';

Component.registerHooks(['validations'])
@Component({
  components: {
    SygniInputError,
    SygniToggleSwitch,
    SygniRadio,
    ExpandCollapse,
    SygniRoundedButton, SygniTextArea, SygniCheckbox, SygniSelect, SygniInput, SygniContainerTitle, GenproxButton, PencilSquareIcon},
  computed: {
    ...mapGetters('dictionary', {
      selects: 'investorsSelects'
    }),
    ...mapState('investors', {
      editInvestmentClientData: (state: any) => state.editInvestmentClientData,
      propagate: (state: any) => state.propagate,
    })
  }
})
export default class AddNewInvestorFormStepOne extends AddNewInvestorBoilerplate {
  PencilSquareIcon = PencilSquareIcon;

  @Prop({default: InvestorFormViewType.newInvestor}) viewType!: InvestorFormViewType;
  @Prop() investorId!: string;

  type: 'investmentClient' | 'legalEntity' = 'investmentClient'
  legalEntityOptions: SelectOption[] = []
  legalEntityId: string = null

  editInvestmentClientData!: InvestmentClient;
  propagate!: boolean;
  investmentData: InvestmentClient = {} as InvestmentClient;
  selects!: InvestorSelects;
  industries: Array<any> = [];

  setPropagate(value: boolean) {
    this.$store.commit('investors/setPropagate', value)
  }

  get isReadOnly() {
    return this.viewType === InvestorFormViewType.previewInvestor
  }

  get cancelBtnLink() {
    if (this.$route.query?.type === 'subscription_product') {
      return `/${this.$route.path.includes('company') ? 'company' : 'fund'}/fundraising/subscription-product/new/step-one`
    }

    return this.capitalRiseLink
  }

  get title(): string {
    switch (this.viewType) {
      case InvestorFormViewType.editInvestor:
          return 'Edit investor';
      case InvestorFormViewType.previewInvestor:
          return 'Investor details';
      case InvestorFormViewType.newInvestor:
        return 'Add new investor';
      default:
        return '';
    }
  }

  get consents() {
    const consents: any = {}

    const privacy = this.investmentData.consents?.find((el: any) => el.type === 'privacyPolicyAndTermsAndConditions')
    const risk = this.investmentData.consents?.find((el: any) => el.type === 'consentToInvestmentRisks')
    const consent1 = this.investmentData.consents?.find((el: any) => el.type === 'consentToTheProcessingOfDataForMarketingPurposes')
    const consent2 = this.investmentData.consents?.find((el: any) => el.type === 'consentToTheProcessingOfDataUsingTelecommunicationsTerminalEquipmentAndAutomaticCallingSystems')

    if (privacy) {
      consents.privacy = privacy
    }

    if (risk) {
      consents.risk = risk
    }

    if (consent1) {
      consents.consent1 = consent1
    }

    if (consent2) {
      consents.consent2 = consent2
    }
    
    return consents
  }

  get nextBtnText() {
    if (this.type === 'legalEntity') {
      return 'Add'
    }

    return this.skip2ndStep && this.skip3rdStep ? 'Save' : 'Next'
  }

  @Watch('selects',{ deep: true, immediate: true}) onSelectsLoaded(){
      if(this.industries.length === 0 && this.selects?.industries?.length > 0){
        this.industries = this.selects.industries.map( industry => ({label: industry.label, value: false}));
        this.investmentData.industry?.forEach( investorIndustry => {
          this.industries.find( industry => industry.label === investorIndustry).value = true;
        });
      }
  }

  loadConsents(investmentData: any) {
    if (this.displayField('privacy_policy_and_terms_and_conditions') && !investmentData?.consents?.find((el: any) => el?.type === 'privacyPolicyAndTermsAndConditions')) {
      investmentData.consents.push({
        type: 'privacyPolicyAndTermsAndConditions',
        value: false,
        date: null
      })
    }
    if (this.displayField('consent_to_investment_risks') && !investmentData?.consents?.find((el: any) => el?.type === 'consentToInvestmentRisks')) {
      investmentData.consents.push({
        type: 'consentToInvestmentRisks',
        value: false,
        date: null
      })
    }
    if (this.displayField('consent_to_the_processing_of_data_for_marketing_purposes') && !investmentData?.consents?.find((el: any) => el?.type === 'consentToTheProcessingOfDataForMarketingPurposes')) {
      investmentData.consents.push({
        type: 'consentToTheProcessingOfDataForMarketingPurposes',
        value: false,
        date: null
      })
    }
    if (this.displayField('consent_to_the_processing_of_data_using_telecommunications_terminal_equipment_and_automatic_calling_systems') && !investmentData?.consents?.find((el: any) => el?.type === 'consentToTheProcessingOfDataUsingTelecommunicationsTerminalEquipmentAndAutomaticCallingSystems')) {
      investmentData.consents.push({
        type: 'consentToTheProcessingOfDataUsingTelecommunicationsTerminalEquipmentAndAutomaticCallingSystems',
        value: false,
        date: null,
      })
    }
  }

  async beforeMount() {
    const leOptions = await this.$store.dispatch('investors/getLegalEntitiesList')
    
    this.legalEntityOptions = leOptions.map((el: any) => {
      return { value: el.id, label: el.name }
    })

    switch (this.viewType){
      case InvestorFormViewType.newInvestor:
        // eslint-disable-next-line no-case-declarations
        const eInvestmentClient = _.cloneDeep(emptyInvestmentClient)

        this.loadConsents(eInvestmentClient);
        this.$store.commit('investors/setNewInvestor', eInvestmentClient);
        this.investmentData = eInvestmentClient
        
        this.$nextTick(() => {
          if(this.industries.length > 0) {
            this.investmentData.industry?.forEach( investorIndustry => {
              this.industries.find( industry => industry.label === investorIndustry).value = true;
            });
          }
        })

        break;
      case InvestorFormViewType.previewInvestor:
      case InvestorFormViewType.editInvestor: 
        this.loadConsents(this.editInvestmentClientData)
        this.investmentData = this.editInvestmentClientData;

        if(this.industries.length > 0) {
          this.investmentData.industry?.forEach( investorIndustry => {
            this.industries.find( industry => industry.label === investorIndustry).value = true;
          });
        }
        break;
    }
  }

  async goNext() {
    this.investmentData.industry = this.industries?.filter( industry => industry.value )?.map( industry => industry.label);
    this.$v?.$touch();

    if(!this.$v.$error) {
      if (this.type === 'investmentClient') {
        switch (this.viewType){
          case InvestorFormViewType.editInvestor:
          case InvestorFormViewType.previewInvestor:
            this.$store.commit('investors/setInvestmentClientToEdit', this.investmentData);
            if(this.$route.query?.productId) {
              this.$router.push({ path: `step-two?productId=${this.$route.query?.productId}` });
            } else {
              this.$router.push({ path: 'step-two' });
            }
            break;
          case InvestorFormViewType.newInvestor:
            this.$store.commit('investors/setNewInvestmentClient', this.investmentData);
            // eslint-disable-next-line no-case-declarations
            const subscriptionId = this.$route.query?.subscriptionId;
            // eslint-disable-next-line no-case-declarations
            const query: any = this.$route.query?.type === 'subscription_product' ? { type: 'subscription_product', productType: this.$route.query?.productType?.toString() || undefined } : undefined;

            if (subscriptionId && query !== undefined) {
              query['subscriptionId'] = subscriptionId?.toString()
            }

            this.$router.push({ name: 'newInvestorFormStepTwo', path: `/${this.$route.path.includes('company') ? 'company' : 'fund'}/fundraising/new-investor/form/step-two`, query})
            break;
        }
      }

      if (this.type === 'legalEntity') {
        const routeParams: any = { investorId: this.legalEntityId, isLegalEntity: '1', productType: this.$route.query?.productType?.toString() || undefined }
        const subscriptionId = this.$route.query?.subscriptionId
        if (subscriptionId) {
          routeParams['subscriptionId'] = subscriptionId?.toString()
        }
        this.$router.push({ name: 'addNewProductStepOne', path: `/${this.$route.path.includes('company') ? 'company' : 'fund'}/fundraising/product/new/step-one`, params: routeParams})
      }
    } else {
      this.$nextTick(() => {
        this.scrollToError();
      })
    }
  }

  get validationObject() {

    if (this.type === 'legalEntity') {
      return {
        legalEntityId: { required },
      }
    }

    const validation: any = {
      investmentData: {
        firstName: { required },
        surname: { required },
        email: { required, email: (val: any) => (email as any)(val.toLowerCase()) },
      },
      consents: {
        privacy: {},
        consent1: {},
        consent2: {},
        risk: {},
      }
    };

    if(this.isLeadFormFieldRequired('investment_potential')) {
      validation.investmentData.investmentPotential = { required }
    }

    if(this.isLeadFormFieldRequired('investment_knowledge')) {
      validation.investmentData.investmentKnowledge = { required }
    }

    if(this.isLeadFormFieldRequired('industry')) {
      validation.investmentData.industry = { required }
    }

    if(this.isLeadFormFieldRequired('notes')) {
      validation.investmentData.notes = { required }
    }

    if(this.isLeadFormFieldRequired('privacy_policy_and_terms_and_conditions')) {
      validation.consents.privacy = { value: { required, checked: (value: boolean) => { return value; } } }
    }

    if(this.isLeadFormFieldRequired('consent_to_the_processing_of_data_for_marketing_purposes')) {
      validation.consents.consent1 = { value: { required, checked: (value: boolean) => { return value; } } }
    }

    if(this.isLeadFormFieldRequired('consent_to_the_processing_of_data_using_telecommunications_terminal_equipment_and_automatic_calling_systems')) {
      validation.consents.consent2 = { value: { required, checked: (value: boolean) => { return value; } } }
    }

    if(this.isLeadFormFieldRequired('consent_to_investment_risks')) {
      validation.consents.risk = { value: { required, checked: (value: boolean) => { return value; } } }
    }

    return this.viewType !== InvestorFormViewType.previewInvestor ? validation : {}; 
  }

  validations() {
    return this.validationObject;
  }
}

